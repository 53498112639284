var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "propertyTypeAdd" }, [
    _c("div", { staticClass: "content bgFFF" }, [
      _c("div", { staticClass: "commonF padding20" }, [
        _c("h2", { staticClass: "title" }, [
          _c("div", { staticClass: "title_icon" }),
          _vm._v(" " + _vm._s(_vm.isEdit ? "编辑" : "添加") + "物品类型 "),
        ]),
        _c(
          "div",
          {},
          [
            _c(
              "el-form",
              {
                ref: "propertyForm",
                staticClass: "demo-form-inline",
                attrs: {
                  inline: false,
                  "label-position": "right",
                  model: _vm.formInline,
                  "label-width": "100px",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-form-item",
                  {
                    attrs: {
                      label: _vm.$t("searchModule.Merchant_affiliation"),
                      prop: "operationId",
                    },
                  },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { width: "200px" },
                        attrs: {
                          filterable: "",
                          size: "15",
                          disabled: _vm.isEdit,
                        },
                        model: {
                          value: _vm.formInline.operationId,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.formInline,
                              "operationId",
                              typeof $$v === "string" ? $$v.trim() : $$v
                            )
                          },
                          expression: "formInline.operationId",
                        },
                      },
                      _vm._l(_vm.tenantList, function (value) {
                        return _c("el-option", {
                          key: value.operationId,
                          attrs: {
                            label: value.operationName,
                            value: value.operationId,
                          },
                        })
                      }),
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: "类型名称", prop: "assetsTypeName" } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: { maxlength: "20", placeholder: "请输入类型名称" },
                      model: {
                        value: _vm.formInline.assetsTypeName,
                        callback: function ($$v) {
                          _vm.$set(
                            _vm.formInline,
                            "assetsTypeName",
                            typeof $$v === "string" ? $$v.trim() : $$v
                          )
                        },
                        expression: "formInline.assetsTypeName",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-form-item",
                  { attrs: { label: _vm.$t("searchModule.remarks") } },
                  [
                    _c("el-input", {
                      staticStyle: { width: "200px" },
                      attrs: {
                        maxlength: 50,
                        placeholder: "请输入备注",
                        type: "textarea",
                      },
                      model: {
                        value: _vm.formInline.remarks,
                        callback: function ($$v) {
                          _vm.$set(_vm.formInline, "remarks", $$v)
                        },
                        expression: "formInline.remarks",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.submit()
                      },
                    },
                  },
                  [_vm._v("提交")]
                ),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary", loading: _vm.loading },
                    on: {
                      click: function ($event) {
                        return _vm.$router.go("-1")
                      },
                    },
                  },
                  [_vm._v("取消")]
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }